<template>
  <v-container class="pa-0 fill-height">
    <v-row justify="center" align="center">
      <v-data-table :headers="headers" :items="companies" :loading="loading" sort-by="name" class="elevation-1" :search="search">
        <template v-slot:top>
          <v-toolbar flat>
            <v-toolbar-title>Sociétes</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
              clearable
              class="mx-8"
              :background-color="search ? 'blue-grey darken-1' : '#272727'"
            ></v-text-field>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-spacer></v-spacer>
            <v-dialog v-model="dialog" min-width="600px" :max-width="$vuetify.breakpoint.mdAndDown ? '100vw' : '50vw'">
              <template v-slot:activator="{ on }">
                <v-btn color="primary" dark class="mb-2" v-on="on">Nouvelle société</v-btn>
              </template>
              <v-card>
                <vue-element-loading :active="loading_form" spinner="bar-fade-scale" :background-color="'rgba(0, 0, 0, 0.3)'">
                  <semipolar-spinner :animation-duration="1200" :size="95" color="#ff1d5e" />
                </vue-element-loading>
                <v-card-title>
                  <span class="text-h5">{{ formTitle }}</span>
                </v-card-title>

                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="12" sm="6" md="4">
                        <v-text-field v-model="editedItem.name" label="Nom"></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="6">
                        <v-file-input
                          color="deep-purple accent-4"
                          counter
                          v-model="editedItem.logo"
                          accept="image/*"
                          label="Enregistrer le logo de l'entreprise"
                          prepend-icon="mdi-database-marker"
                          chips
                          :rules="logoRules"
                          :show-size="1000"
                        ></v-file-input>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="close">Cancel</v-btn>
                  <v-btn color="blue darken-1" text @click="save">Save</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-toolbar>
        </template>
        <template v-slot:item.logo.name="{ item }">
          <a v-if="item.logo" @click="getLogo(item)">{{ item.logo.name }}</a>
        </template>
        <!-- <template v-slot:item.ref_concession_by_id.name="{ item }">
          <router-link :to="`/companies/${item.ref_concession_by_id._id}`">{{ item.ref_concession_by_id.name }}</router-link>
        </template>
        <template v-slot:item.ref_groupe_by_id.name="{ item }">
          <router-link :to="`/company/${item.ref_groupe_by_id._id}`">{{ item.ref_groupe_by_id.name }}</router-link>
        </template>
        <template v-slot:item.ref_franchise_by_id.name="{ item }">
          <router-link :to="`/companies/${item.ref_franchise_by_id._id}`">{{ item.ref_franchise_by_id.name }}</router-link>
        </template>
        <template v-slot:item.roles="{ item }">
          <v-chip
            v-for="role in item.roles"
            :key="role"
            class="grey--text text--lighten-5 ma-1"
            color="black lighten-3"
            dark
            label
            small
            >{{ role }}</v-chip
          >
        </template> -->
        <template v-slot:item.actions="{ item }">
          <v-icon small class="mr-2" @click="editItem(item)">mdi-pencil</v-icon>
          <v-icon small @click="deleteItem(item)">mdi-delete</v-icon>
        </template>
        <template v-slot:no-data>
          <v-btn color="primary" @click="initialize">Reset</v-btn>
        </template>
      </v-data-table>
    </v-row>
  </v-container>
</template>

<script>
import { SemipolarSpinner } from "epic-spinners";

import snackBar from "@/utils/mixins/snackBar";

export default {
  name: "Company",

  mixins: [snackBar],

  components: { SemipolarSpinner },

  data: () => ({
    companies: [],
    loading: false,
    loading_form: false,

    search: "",
    dialog: false,
    mode: "new",
    headers: [
      { text: "Nom", value: "name" },
      { text: "Logo", value: "logo.name" },
      { text: "Slide personnalisée", value: "logo.custom_slide" },
      { text: "Actions", value: "actions", sortable: false },
    ],
    editedIndex: -1,
    defaultItem: {
      _id: "",
      name: "",
      custom_slide: [],
      logo: {
        _id: "",
        name: "",
        path: "",
      },
    },
    editedItem: {
      _id: "",
      name: "",
      custom_slide: [],
      logo: {
        _id: "",
        name: "",
        path: "",
      },
    },
    logoRules: [(value) => !value || value.size < 5000000 || "Le logo doit avoir une taille en octet inférieur à 5MB"],
  }),

  async mounted() {
    this.initialize();
  },

  methods: {
    async getCompanies() {
      this.loading = true;
      try {
        this.companies = (await this.$axios.get("/all_companies")).data;
      } catch (error) {
        console.error(error);
        this.displayErrorSnack(`Couldn't retrieve companies infos ... (${error})`);
      } finally {
        this.loading = false;
      }
    },

    initialize() {
      this.getCompanies();
      if (this.$route.params.name) this.search = this.$route.params.name;
    },

    editItem(item) {
      this.mode = "edit";
      this.editedIndex = this.companies.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    async deleteItem(item) {
      const index = this.companies.indexOf(item);
      if (index >= 0 && confirm("Are you sure you want to delete this item?")) {
        try {
          await this.$axios.delete("/company", { data: { companyId: item._id } });
          this.companies.splice(index, 1);
        } catch (error) {
          console.error(error);
          this.displayErrorSnack(error);
        }
      }
    },

    async deleteLogo() {
      // console.log("start deleteLogo", this.editedIndex, this.companies[this.editedIndex].logo._id);

      if (
        this.editedIndex >= 0 &&
        this.companies[this.editedIndex].logo &&
        this.companies[this.editedIndex].logo._id &&
        // !(this.editedItem.logo instanceof File)
        JSON.stringify(this.editedItem.logo) != JSON.stringify(this.companies[this.editedIndex].logo)
      ) {
        await this.$axios.delete("/admin/company/logo", { data: { companyId: this.editedItem._id } });
        this.companies[this.editedIndex].logo = Object.assign({}, this.defaultItem.logo);
        // return Object.assign({}, this.defaultItem.logo);
      }
      return this.companies[this.editedIndex].logo;
    },

    close() {
      this.dialog = false;
      this.mode = "new";
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      }, 300);
    },

    async save() {
      if (this.editedIndex > -1) {
        //edit
        await this.handleEditCompany();
      } else {
        //new
        await this.handleNewCompany();
      }
      this.close();
    },

    async handleNewCompany() {
      if (!this.editedItem.name) return;
      try {
        let company = (await this.$axios.post("company", { name: this.editedItem.name })).data;

        let logo = await this.addLogoCompany(company._id);

        this.companies.push({ ...company, logo: logo });
      } catch (error) {
        console.error(error);
        this.displayErrorSnack(error);
      }
    },

    async addLogoCompany(companyId) {
      if (
        this.editedItem.logo &&
        this.editedItem.logo instanceof File &&
        (this.editedIndex <= -1 ||
          // !this.companies[this.editedIndex].logo.name ||
          !this.companies[this.editedIndex].logo ||
          this.companies[this.editedIndex].logo._id != this.editedItem.logo._id)
      ) {
        try {
          const formData = new FormData();
          formData.append("companyId", companyId);
          formData.append("file", this.editedItem.logo);
          const config = {
            headers: {
              "content-type": "multipart/form-data",
            },
          };

          let res = (await this.$axios.post("/admin/company/logo", formData, config)).data;
          return res;
        } catch (error) {
          console.error(error);
          this.displayErrorSnack(error);
        }
      }
      return this.editedIndex >= 0 ? this.companies[this.editedIndex].logo : Object.assign({}, this.defaultItem.logo);
    },

    async handleEditCompany() {
      if (!this.editedItem.name) return;
      try {
        this.loading_form = true;

        //delete
        let logo = await this.deleteLogo();
        //add
        let company = await this.editCompanyName();
        logo = await this.addLogoCompany(company._id);

        // this.companies.push({ ...company, logo: logo });
        Object.assign(this.companies[this.editedIndex], { ...company, logo });
      } catch (error) {
        console.error(error);
        this.displayErrorSnack(error);
      } finally {
        this.loading_form = false;
      }
    },

    async editCompanyName() {
      if (this.editedItem.name && this.editedItem.name != this.companies[this.editedIndex].name) {
        let res = (await this.$axios.put("company", { companyId: this.editedItem._id, name: this.editedItem.name })).data;
        return res;
      }
      return this.companies[this.editedIndex];
    },

    async getLogo(item) {
      try {
        console.log(item.logo.name);

        const { url } = (await this.$axios.get(`/admin/link/logo?companyId=${item._id}`)).data;

        const obj = window.URL.createObjectURL(new Blob([url]));
        const link = document.createElement("a");
        link.href = url;

        // link.setAttribute("download", "item.logo.name"); //or any other extension
        document.body.appendChild(link);
        link.click();

        link.remove();

        // console.log(url);
      } catch (error) {
        console.error(error);
        this.displayErrorSnack(error);
      }
    },
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "New Item" : "Edit Item";
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
  },
};
</script>

<style lang="scss" scoped></style>
